<template>
    <el-scrollbar class="uu-box">
        <div class="page-breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.seller')}}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.orderManage') }}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'tradePlatform' }">{{ $t('seller.breadcrumb.sellGoods') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.viewDetail') }}</el-breadcrumb-item>
			</el-breadcrumb>
        </div>
        <div class="page-head">
            <div class="head-status">
                <h3>{{ $t('seller.screen.orderStatus') }}：{{ orderInfo.status | statusFilter($t('seller.filters.orderStatus')) }}</h3>
                <div class="status-bar" v-if="orderInfo.status > 0">
                    <el-steps :active="orderStatus" finish-status="success" simple>
                        <el-step v-for="item in statusOption" :key="item" :title="item"></el-step>
                    </el-steps>
                </div>
                <div></div>
            </div>
            <ul class="head-actions">
                <li v-if="orderInfo.status === 11">
                    <el-button @click="handleShow(orderInfo.order_sn)" size="medium" round>{{$t('seller.actions.closePay')}}</el-button>
                </li>
                <template v-if="orderInfo.status === 20">
                    <li>
                        <router-link :to="{ name: 'consignOrder', params: { type:'list',id: orderInfo.order_id }}">
                            <el-button type="primary" size="medium" round>{{$t('seller.actions.shipping')}}</el-button>
                        </router-link>
                    </li>
                    <li>
                        <el-button @click="handleEditAddressShow()" size="medium" round>{{$t('seller.actions.editBuyerAddress')}}</el-button>
                    </li>
                </template>
            </ul>
            <div class="head-bottom" v-if="orderInfo.status === 0">
                {{ $t('seller.order.reasonReason') + ':' }}{{ orderInfo.cancel_info.remark }}
            </div>
            <el-dialog
				:title="$t('seller.dialogTitle.modifyAddress')"
				width="876px"
				class="dialog-address"
				:visible.sync="dialogVisibleAddress"
				:before-close="handleEditAddressHide">
				<el-scrollbar>
					<div class="dialog-address-main">
						<div class="item">
							<div class="item-label">{{$t('seller.order.dialogAddress1')}}</div>
							<div class="item-block">{{orderInfo.order_sn}}</div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('seller.order.dialogAddress2')}}</div>
							<div class="item-block">
								<div class="address-line">
									<p><span class="line-label">{{$t('seller.order.consignee')}}</span><span>{{logisticsInfo?logisticsInfo.consignee:''}}</span></p>
									<p><span class="line-label">{{$t('seller.order.receivingTel')}}</span><span>{{logisticsInfo?logisticsInfo.phone_mob:''}}</span></p>
									<p><span class="line-label">{{$t('seller.order.receivingAddress')}}</span><span>{{logisticsInfo?logisticsInfo.region_name:''}}{{logisticsInfo?logisticsInfo.address:''}}</span></p>
								</div>
							</div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('seller.order.dialogAddress3')}}</div>
							<div class="item-block">
							</div>
						</div>
						<div class="item">
							<el-form label-position="top" size="small" style="width: 100%;">
								<el-row :gutter="20">
									<el-col :span="12">
										<el-form-item>
											<div class="item-title" slot="label">
												<span>{{$t('seller.formItem.consignee')}}</span>
												<a href="javascript:void(0);" @click="handleEditAddressAction('consignee')">{{addressDisabled.consignee?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
											</div>
											<el-input v-model="addressForm.consignee" :disabled="addressDisabled.consignee"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item>
											<div class="item-title" slot="label">
												<span>{{$t('seller.formItem.receivingTel')}}</span>
												<a href="javascript:void(0);" @click="handleEditAddressAction('phone_mob')">{{addressDisabled.phone_mob?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
											</div>
											<el-input v-model="addressForm.phone_mob" :maxlength="16" :disabled="addressDisabled.phone_mob"></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<el-form-item>
									<div class="item-title" slot="label">
										<span>{{$t('seller.formItem.landline')}}</span>
										<a href="javascript:void(0);" @click="handleEditAddressAction('phone_tel')">{{addressDisabled.phone_tel?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
									</div>
									<el-row>
										<el-col :span="12"><el-input v-model="addressForm.phone_tel" :maxlength="16" :disabled="addressDisabled.phone_tel" :placeholder="$t('seller.placeholder.landline')"></el-input></el-col>
									</el-row>
								</el-form-item>
								<div class="item">
									<div class="item-title">
										<span>{{$t('seller.formItem.receivingAddress')}}</span>
										<a href="javascript:void(0);" @click="handleEditAddressAction('region')">{{addressDisabled.region?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
									</div>
								</div>
								<div style="padding-left: 20px;">
									<el-row :gutter="20">
										<el-col :span="12" v-for="(item,index) in regionList" :key="index">
											<el-form-item :label="onRegionName(index)">
												<el-select v-model="regionIds[index]" @change="handleAddressChange($event,index)" :disabled="addressDisabled.region" clearable>
													<el-option v-for="address in item" :key="address.region_id" :label="address.region_name"
														:value="address.region_id"></el-option>
												</el-select>
											</el-form-item>
										</el-col>
									</el-row>
									<el-form-item :label="$t('seller.formItem.address')">
										<el-input type="textarea" :rows="4" v-model="addressForm.address" :disabled="addressDisabled.region"></el-input>
									</el-form-item>
								</div>
							</el-form>
						</div>
					</div>
				</el-scrollbar>
				<div slot="footer">
					<el-button @click="handleEditAddressHide">{{$t('seller.actions.cancel')}}</el-button>
					<el-button type="primary" @click="handleEditAddressConfirm" :disabled="confirmDissbled">{{$t('seller.actions.confirm')}}</el-button>
				</div>
			</el-dialog>
        </div>
        <div class="page-main">
            <div class="detail-top">
                <div class="title">{{ $t('seller.order.infoTitle') }}</div>
            </div>
            <div class="detail-layout">
                <div class="item">
                    <div class="item-title">{{ $t('seller.order.tradeInfo') }}</div>
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.number') }}</span>
                        <span class="item-value">
                            {{ orderInfo.order_sn }}
                            <!-- <span class="copy">{{ $t('seller.actions.copy') }}</span> -->
                        </span>
                    </div>
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.outTradeSn') }}</span>
                        <span class="item-value">
                            {{ orderInfo.out_trade_sn }}
                            <!-- <span class="copy">{{ $t('seller.actions.copy') }}</span> -->
                        </span>
                    </div>
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.createTime') }}</span>
                        <span class="item-value">{{ orderInfo.add_time | parseTime() }}</span>
                    </div>
                    <div class="item-row" v-if="orderInfo.status > 11">
                        <span class="item-label">{{ $t('seller.order.paymentTime') }}</span>
                        <span class="item-value">{{ orderInfo.pay_time | parseTime() }}</span>
                    </div>
                    <div class="item-row" v-if="orderInfo.status === 40">
                        <span class="item-label">{{ $t('seller.order.dealTime') }}</span>
                        <span class="item-value">{{ orderInfo.finished_time | parseTime() }}</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item-title">{{ $t('seller.order.buyerInfo') }}</div>
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.nickname') }}</span>
                        <span class="item-value">{{ memberInfo.user_name }}</span>
                    </div>
                    <!-- <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.location') }}</span>
                        <span class="item-value"></span>
                    </div> -->
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.telephone') }}</span>
                        <span class="item-value">{{ memberInfo.phone_mob }}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.email') }}</span>
                        <span class="item-value">{{ memberInfo.email || '-' }}</span>
                    </div>
                    <!-- <div class="item-row">
                        <span class="item-label">支付宝:</span>
                        <span class="item-value"></span>
                    </div> -->
                </div>
                <div class="item">
                    <div class="item-title">{{ $t('seller.order.logisticsInfo') }}</div>
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.receiveAddress') }}</span>
                        <span class="item-value">
                            <template v-if="logisticsInfo">
                                {{ logisticsInfo.consignee + ',' }}
                                {{ logisticsInfo.phone_mob || logisticsInfo.phone_tel}},
                                {{ logisticsInfo.region_name + logisticsInfo.address + ',' }}
                                {{ logisticsInfo.zipcode || '000000' }}
                            </template>
                        </span>
                    </div>
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.shippingType') }}</span>
                        <span class="item-value">快递</span>
                    </div>
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.shippingName') }}</span>
                        <span class="item-value">{{ logisticsInfo ? logisticsInfo.shipping_name : '-'}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-label">{{ $t('seller.order.logisticsCode') }}</span>
                        <span class="item-value">{{ orderInfo.logistic_code }}</span>
                    </div>
                </div>
            </div>
            <el-table :data="orderGoods" :span-method="objectSpanMethod">
              <el-table-column :label="$t('seller.tableHead.baby')" min-width="300px" align="center">
                  <template slot-scope="scope">
                      <div class="item-goods">
                          <a :href="$itemBasePath+'/m/item/good/'+scope.row.goods_id" target="_blank" class="item-pic"><img :src="scope.row.goods_image" alt=""></a>
                          <div class="item-main">
                              <a :href="$itemBasePath+'/m/item/good/'+scope.row.goods_id" target="_blank" class="name">{{ scope.row.goods_name }}</a>
                          </div>
                      </div>
                  </template>
              </el-table-column>
              <el-table-column :label="$t('seller.tableHead.babyAttribute')" prop="specification" align="center"></el-table-column>
              <el-table-column :label="$t('seller.tableHead.status')" align="center">
                {{ orderInfo.status | statusFilter($t('seller.filters.orderStatus')) }}
              </el-table-column>
              <!-- <el-table-column :label="$t('seller.tableHead.service')" align="center"></el-table-column> -->
              <el-table-column :label="$t('seller.tableHead.unitPrice')" prop="price" align="center"></el-table-column>
              <el-table-column :label="$t('seller.tableHead.number')" prop="quantity" align="center"></el-table-column>
              <el-table-column :label="$t('seller.tableHead.discount')" align="center">
                  {{ orderInfo.discount }}
              </el-table-column>
              <el-table-column :label="$t('seller.tableHead.goodsTotal')">
                  {{ orderInfo.order_amount}}
              </el-table-column>
            </el-table>
        </div>
        <el-dialog
            :visible.sync="orderVisible"
            width="600px"
            :before-close="handleHide">
            <div class="message-notice">
                <i class="el-icon-warning"></i>
                <div class="message-notice-main">
                    <p>{{$t('seller.order.closeTips1')}}</p>
                </div>
            </div>
            <el-form>
                <el-form-item :label="$t('seller.formItem.closeReason')">
                    <el-select v-model="closeOrder.remark" :placeholder="$t('seller.placeholder.closeReason')">
                        <el-option v-for="(item,index) in reasonOption" :key="index" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <div style="text-align: right;">
                        <el-button type="primary" @click="handleClose">{{$t('seller.actions.confirm')}}</el-button>
                        <el-button @click="handleHide">{{$t('seller.actions.close')}}</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <div class="dialog-tip">
                <div class="tip-label">{{$t('seller.order.closeTips2')}}</div>
                <ul class="tip-block">
                    <li>{{$t('seller.order.closeTips3')}}</li>
                    <li>{{$t('seller.order.closeTips4')}}</li>
                </ul>
            </div>
        </el-dialog>
    </el-scrollbar>
</template>

<script>
import { fetchRegion } from '@/api/user'
import { fetchOrder, updateAddress, closeOrder, getCloseReasons } from "@/api/seller/order"

const defaultCloseForm = {
    order_sn: '',
    remark: ''
}

const defaultAddress = {
    address: '',
    consignee: '',
    phone_mob: '',
    phone_tel: '',
    region_id: 0,
    region_id0: 0,
    region_id1: 0,
    region_id2: 0,
    region_id3: 0,
    region_name: '',
    zipcode: '',
}

const defaultDisabled = { consignee: true, phone_mob: true, phone_tel: true, region: true }

export default {
    filters: {
        statusFilter(status, option) {
            return option[status]
        }
    },
    computed: {
        orderStatus() {
            let status = 0
            switch(this.orderInfo.status) {
                case 0:
                    status = 0
                    break;
                case 11:
                    status = 1
                    break;
                case 20:
                    status = 2
                    break;
                case 30:
                    status = 3
                    break;
                case 40:
                    status = 4
                    break;
            }
            return status
        }
    },
    data() {
        return {
            orderInfo: {},
            orderGoods: [],
            logisticsInfo: null,
            memberInfo: {},
            statusOption: this.$t('seller.order.stepsOption'),
            orderVisible: false,
            closeOrder: Object.assign({}, defaultCloseForm),
            reasonOption: [],
            regionList: [],
            regionIds: [],
            dialogVisibleAddress: false,
            addressForm: Object.assign({},defaultAddress),
            addressDisabled: Object.assign({},defaultDisabled),
            confirmDissbled: true
        }
    },
    created () {
        this.getReasonOption()
        this.getOrderInfo()
        this.getRegionList()
    },
    methods: {
        getReasonOption() {
            getCloseReasons({ uuid: this.$store.getters.uuid }).then( response => {
                const { data } = response
                this.reasonOption = data.cancel_order_reasons || []
            })
        },
        getOrderInfo() {
            const params = {
                order_id: this.$route.params.id
            }
            fetchOrder(params).then(response => {
                this.orderInfo = response.data
                this.orderGoods = response.data.order_goods
                this.logisticsInfo = response.data.order_extm
                this.memberInfo = response.data.member_info
                this.addressForm = response.data.order_extm
            })
        },
        getRegionList(pid = 45056, index = -1) {
            fetchRegion({parent_id: pid}).then(response => {
                this.regionList.splice(index);
                if (response.data.list && response.data.list.length > 0) {
                    this.regionList.push(response.data.list);
                }
            })
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 5 || columnIndex === 6) {
                return {
                    rowspan: this.orderGoods.length,
                    colspan: 1
                };
            }
        },
        handleShow(number) {
            this.orderVisible = true
            this.closeOrder.order_sn = number
        },
        handleHide() {
            this.orderVisible = false;
        },
        handleClose() {
            if(this.closeOrder.remark === '') {
                this.$message(this.$t('seller.infoMsg.closeReason'))
                return false
            }
            closeOrder(this.closeOrder).then(() => {
                this.orderVisible = false
                this.getOrderInfo()
                this.$message.success(this.$t('seller.successMsg.close'))
            })
        },
        handleEditAddressShow(){
            let promiseArr = [];
            let regionIds = [];
            this.addressForm = JSON.parse(JSON.stringify(this.logisticsInfo))
            this.regionList.splice(1);
            for(let i=0;i<4;i++){
                if(this.addressForm['region_id' + i] > 0){
                    regionIds.push(this.addressForm['region_id' + i]);
                    promiseArr.push(this.initRegionList(this.addressForm['region_id' + i]));
                }
            }
            Promise.all(promiseArr).then(object=>{
                object.forEach(item=>{
                    if(item.length>0){
                        this.regionList.push(item);
                    }
                })
            })
            this.regionIds = regionIds;
            this.dialogVisibleAddress = true;
        },
        initRegionList(pid=45056){
            return new Promise((resolve,reject)=>{
                fetchRegion({parent_id: pid}).then(response => {
                    resolve(response.data.list)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        handleEditAddressHide(){
            this.dialogVisibleAddress = false;
        },
        onRegionName(index){
            const regionJson =  this.$t('seller.order.regionJson')
            return regionJson[index];
        },
        handleAddressChange(val, index){
            if(val){
                this.regionIds.splice(index + 1);
                this.getRegionList(val, index + 1);
            }else {
                this.regionIds.splice(index);
                this.regionList.splice(index+1);
            }
        },
        handleEditAddressAction(type){
            let that = this;
            if(!this.addressDisabled[type]){
                let data = JSON.parse(JSON.stringify(this.logisticsInfo));
                let promiseArr = [];
                let regionIds = [];
                switch(type){
                    case 'consignee':
                        this.addressForm.consignee = data.consignee;
                    break;
                    case 'phone_mob':
                        this.addressForm.phone_mob = data.phone_mob;
                        break;
                    case 'phone_tel':
                        this.addressForm.phone_tel = data.phone_tel;
                        break;
                    case 'region':
                        that.regionList.splice(1);
                        for(let i=0;i<4;i++){
                            if(that.addressForm['region_id' + i] > 0){
                                regionIds.push(that.addressForm['region_id' + i]);
                                promiseArr.push(that.initRegionList(that.addressForm['region_id' + i]));
                            }
                        }
                        Promise.all(promiseArr).then(object=>{
                            object.forEach(item=>{
                                if(item.length>0){
                                    that.regionList.push(item);
                                }
                            })
                        })
                        that.regionIds = regionIds;
                        this.addressForm.address = data.address;
                        break;
                }
            }
            this.addressDisabled[type] = this.addressDisabled[type]?false:true;
        },
        handleEditAddressConfirm(){
            if(!this.addressForm.consignee){
                this.$message.error(this.$t('seller.errorMsg.consignee'))
                return false
            }
            if(!this.addressForm.phone_mob){
                this.$message.error(this.$t('seller.errorMsg.recipientMobile'))
                return false
            }
            if(this.regionIds){
                this.addressForm.region_id = this.regionIds[this.regionIds.length-1]
                this.addressForm.region_id0 = this.regionIds[0] > 0 ? this.regionIds[0] : 0
                this.addressForm.region_id1 = this.regionIds[1] > 0 ? this.regionIds[1] : 0
                this.addressForm.region_id2 = this.regionIds[2] > 0 ? this.regionIds[2] : 0
                this.addressForm.region_id3 = this.regionIds[3] > 0 ? this.regionIds[3] : 0
                let regionName = ''
                for(let i = 0;i<this.regionIds.length;i++){
                    this.regionList[i].forEach(item=>{
                        if(item.region_id == this.regionIds[i]){
                            regionName+=item.region_name
                        }
                    })
                }
                this.addressForm.region_name = regionName
            }else {
                this.$message.error(this.$t('seller.errorMsg.region'))
                return false
            }
            if(!this.addressForm.address){
                this.$message.error(this.$t('seller.errorMsg.address'))
                return false
            }
            let loading = this.$loading();
            let param = {
                ...this.addressForm,
                order_id: this.orderInfo.order_id
            }
            updateAddress(param).then(() => {
                loading.close()
                this.$message.success(this.$t('seller.successMsg.modify'))
                this.handleEditAddressHide()
                this.getOrderInfo()
                this.addressDisabled = Object.assign({},defaultDisabled)
            }).catch(() => {
                loading.close()
            })
        }
    },
    watch: {
        'addressForm': {
            handler: function(val, oldVal){
                if (JSON.stringify(val) === JSON.stringify(this.logisticsInfo)) {
                    this.confirmDissbled = true
                } else {
                    this.confirmDissbled = false
                }
            },
            deep: true,
        }
    }
}
</script>

<style lang="scss" scoped>
.uu-box {
    ::v-deep .el-scrollbar__view {
        padding: 20px;
        box-sizing: border-box;
    }
}
.page-breadcrumb {
    margin-bottom: 20px;
    ::v-deep .el-breadcrumb {
        .el-breadcrumb__item {
            .el-breadcrumb__inner {
                color: #999999;
                &.is-link,a {
                    font-weight: normal;
                    color: #999999;
                    &:hover {
                        color: $--seller-primary;
                    }
                }
            }
            &:last-child {
                .el-breadcrumb__inner {
                    color: #333333;
                }
            }
        }
    }
}
.page-head {
    padding: 20px;
    background-color: #f7f8fa;
    border: 1px solid #f7f8fa;
    border-radius: 10px;
    .head-status {
        display: flex;
        justify-content: space-between;
        h3 {
            font-weight: 700;
            font-size: 22px;
            color: #111;
            line-height: 32px;
            margin: 5px 10px 15px 0;
        }
        .status-bar {
            width: 800px;
            ::v-deep .el-steps--simple {
                padding-left: 0;
                padding-right: 0;
                .el-step.is-simple {
                    .el-step__head {
                        &.is-success {
                            color: $--seller-primary;
                            border-color: $--seller-primary;
                        }
                    }
                    &:not(:last-of-type) {
                        .el-step__title {
                            max-width: none;
                            &.is-success {
                                color: $--seller-primary;
                            }
                        }
                    }
                }
            }
        }
    }
    .head-actions {
        li {
            display: inline-block;
            margin-right: 10px;
        }
    }
    .head-bottom {
        color: #999999;
        padding-top: 20px;
        border-top: 1px solid #e5e5e5;
    }
    .dialog-address {
        ::v-deep .el-scrollbar {
            height: 500px!important;
            .el-scrollbar__view {
                padding: 0;
            }
        }
        .dialog-address-main {
            padding: 0 40px;
            overflow: hidden;
            .item {
                display: flex;
                line-height: 32px;
                margin-bottom: 5px;
                font-size: 14px;
                .item-label {
                    width: 100px;
                    padding-right: 10px;
                }
                .item-block {
                    flex: 1;
                    width: 0;
                    display: block;
                }
                .item-title {
                    span {
                        margin-right: 20px;
                    }
                    a {
                        color: $--seller-primary;
                        text-decoration: underline;
                    }
                }
            }
            .address-line {
                padding: 4px 0;
                line-height: 32px;
                .line-label {
                    min-width: 68px;
                    display: inline-block;
                    color: #999999;
                }
            }
            ::v-deep .el-form {
                .el-form-item {
                    margin-bottom: 10px;
                }
            }
            ::v-deep .el-select {
                width: 100%;
            }
        }
    }
}
.page-main {
    margin-top: 10px;
    .detail-top {
        .title {
            font-size: 18px;
            padding: 12px 0;
            font-weight: 700;
            color: #111;
            position: relative;
            display: inline-block;
            &:before {
                content: "";
                width: 50%;
                left: 25%;
                bottom: 0;
                border-bottom: 2px solid $--seller-primary;
                position: absolute;
            }
        }
    }
    .detail-layout {
        margin: 20px 0;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid #f7f8fa;
        background-color: #f7f8fa;
        .item {
            flex: 1;
            .item-title {
                font-weight: 700;
                font-size: 14px;
                color: #111;
                line-height: 15px;
                margin-bottom: 15px;
            }
            .item-row {
                display: flex;
                line-height: 30px;
            }
            .item-label {
                flex-shrink: 0;
                font-size: 14px;
                color: #888888;
                margin-right: 5px;
            }
            .item-value {
                flex: 1;
                width: 0;
                color: #111;
                font-size: 14px;
                .copy {
                    color: $--seller-primary;
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }
    }
    .item-goods {
        display: flex;
        padding: 18px;
        .item-pic {
            width: 68px;
            height: 68px;
            flex-shrink: 0;
            overflow: hidden;
            border-radius: 12px;
            margin-right: 18px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .item-main {
            .name {
                color: $--seller-primary;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
    ::v-deep .el-table {
        th {
            &.el-table__cell {
                background-color: #f7f8fa;
            }
            
        }
    }
}
.message-notice {
    padding: 12px;
    border-radius: 9px;
    line-height: 18px;
    background-color: $--seller-notice-background;
    margin-bottom: 15px;
    
    .el-icon-warning {
        color: $--seller-primary;
        float: left;
        font-size: 18px;
    }
    
    .message-notice-main {
        width: 100%;
        color: #666666;
        font-size: 14px;
        line-height: 22px;
        padding-left: 24px;
        padding-right: 15px;
        box-sizing: border-box;
        
        a {
            color: $--seller-primary;
        }
    }
}
.dialog-tip {
    display: flex;
    line-height: 18px;
    .tip-label {
        color: $--uucolor-danger;
        font-size: 12px;
    }
    .tip-block {
        flex: 1;
        width: 0;
        list-style: decimal;
        padding-left: 16px;
        margin-left: 10px;
        li {
            font-size: 12px;
        }
    }
}
</style>